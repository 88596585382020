import Joi from '@hapi/joi';

const schema = Joi.object()
  .keys({
    ACG_ENV: Joi.string().required(),
    NEXT_PUBLIC_APP_VERSION: Joi.string().required(),
    ACG_B2B_ONBOARDING_DOMAIN: Joi.string().required(),
    GRAPHQL_API_ENDPOINT: Joi.string().required(),
    STAGE: Joi.string(),
    BUILD_NUMBER: Joi.string(),
    BUILD_COMMIT: Joi.string(),
    CYPRESS: Joi.boolean().required(),
    LOG_LEVEL: Joi.string().required(),
    LOG_STAGE: Joi.string().required(),
    AUTH0_CLIENT_ID: Joi.string().required(),
    AUTH0_DOMAIN: Joi.string().required(),
    AUTH0_CUSTOM_DOMAIN: Joi.string().required(),
    AUTH0_API_AUDIENCE: Joi.string().required(),
    BUGSNAG_API_KEY: Joi.string().required(),
    SEGMENT_API_KEY: Joi.string().required(),
    IGNORE_SEGMENT_SEND: Joi.boolean().required(),
    LEARN_SITE: Joi.string().required(),
    PS_LOGOUT_URL: Joi.string().required(),
  })
  .required();

export { schema };
