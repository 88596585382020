const CONFIG_ENV = process.env.NEXT_PUBLIC_REACT_APP_ACG_ENV || process.env.NEXT_PUBLIC_ACG_ENV;

const devConfig = {
  ACG_B2B_ONBOARDING_DOMAIN: 'http://localhost:3000',
  GRAPHQL_API_ENDPOINT: CONFIG_ENV === 'local' ? 'http://localhost:4000' : 'https://test-api.acloud.guru/bff/graphql',
  AUTH0_DOMAIN: 'staging-acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'staging-auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://staging-acloudguru.auth0.com/api/v2/',
  AUTH0_CLIENT_ID: 'fljNY6H0JVMd41gR0o7ATdSiETUD78rh',
  PS_LOGOUT_URL: 'http://app-stage.pluralsight.com/id/signout',
};

export { devConfig };
